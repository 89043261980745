<template>
  <div class="container">
    <div class="max-container">
      <div style="position:absolute;left: 0;right: 0;top: 0">
        <el-image :src="require('../assets/'+project_type+'/'+project_type+'_bg.png')"
                  style="width: 100%;object-fit: contain" lazy></el-image>
      </div>
      <div
          style="position: relative;padding-top: 100px">
        <PrintTop :title="studentInfo.project_title" :name="studentInfo.project_name_en"
                  :project_type="project_type"/>
        <div style="page-break-before: always;"></div>
        <PrintStudent :students="studentInfo.students_arr"
                      :project_type="project_type" style="margin-bottom: 30px"/>
        <div style="page-break-before: always"></div>
        <div v-for="(item) in allContent" :key="item.id">
          <div v-if="item.template_block !== 'Project Title'"
               style="margin-top: 20px;page-break-before: always"
               :class="(item.template_block === 'Presentation Recording' || item.template_block === 'Grant of License' || item.template_block === 'Learning Materials') ? 'other-ele':'ele'">
            <PrintBlockNormal :template_block="item.template_block"
                              :viewType="item.viewType"
                              :project_type="project_type" :dataType="0" :contents="item.blocks"/>
          </div>
        </div>
        <div style="page-break-before: always"></div>
        <PrintBlockNormal :judge-comments="judgeComments" :template_block="'Judge Comments'"
                          :project_type="project_type" :dataType="1"/>
        <div style="width: 100%;height: 30px"></div>
      </div>
      <div v-if="showPdfBtn" style="height: 75px;width: 100%;position: absolute;left: 0;right: 0;top: 20px" >
        <div :style="{'color':getPdfColor(),'border':getPdfBorder()}" class="pdf" @click="p($event)" ref="btnClick">
          <img :src="require('../assets/'+project_type+'/'+project_type+'_pdf_icon.png')"
               style="margin-right: 10px;width: 18px;height: auto">
          <p>生成PDF报告</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import '../assets/common/print-view-css.css'
import '../assets/common/font.css'
import {netService,} from '../service.js'
import PrintTop from "./print/PrintTop";
import PrintStudent from "./print/PrintStudent";
import PrintBlockNormal from "./print/PrintBlockNormal";
import {pdfTextColorMap} from '../GlobalConfig'

export default {
  name: 'Toprint',
  components: {
    PrintTop,
    PrintStudent,
    PrintBlockNormal
  },
  computed: {
    name() {
      return Cookie.get('user_name')
    }
  },
  data() {
    return {
      ALL_PROJECT_TYPES: [
        'FBLA',
        'MR',
        'CHS',
        'SI2',
        'SI',
        'YOC',
        'YIC'
      ],
      project_type: 'DEFAULT',
      allContent: [],
      show: false,
      studentInfo: [],
      judgeComments: [],
      showPdfBtn: true,
      block_id:''
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {

    window.addEventListener('scroll', this.handleScroll)

    var project_product = this.$router.currentRoute.query.project_product
    this.selectUIStyle(project_product)
    this.project_id = this.$router.currentRoute.query.project_id
    this.team_code = this.$router.currentRoute.query.team_code
    this.account_id = this.$router.currentRoute.query.account_id
    this.block_id = this.$router.currentRoute.query.block_id
    this.getPdf()
    this.getProfile()
    this.getJudgeComments()
  },
  methods: {
    handleScroll() {
      let scrollTop = window.document.documentElement.scrollTop
      if (scrollTop >= 400) {
        this.showPdfBtn = false
      } else {
        this.showPdfBtn = true
      }
    },

    selectUIStyle(project_product) {
      //default de
      this.project_type = 'DEFAULT'
      if (project_product == undefined) {
        this.project_type = 'DEFAULT'
      } else if (project_product.indexOf('FBLA') >= 0) {
        this.project_type = 'FBLA'
      } else if (project_product.indexOf('MediaReview') >= 0 || project_product == 'MediaReview') {
        this.project_type = 'MR'
      } else if (project_product.indexOf('CHS') >= 0) {
        this.project_type = 'CHS'
      } else if (project_product.indexOf('SI2') >= 0) {
        this.project_type = 'SI2'
      } else if (project_product.indexOf('SI') >= 0) {
        this.project_type = 'SI'
      } else if (project_product.indexOf('YOC') >= 0) {
        this.project_type = 'YOC'
      } else if (project_product.indexOf('YIC') >= 0) {
        this.project_type = 'YIC'
      }
    },


    getPdfColor() {
      return pdfTextColorMap[this.project_type]
    },
    getPdfBorder() {
      return '1px solid ' + pdfTextColorMap[this.project_type]
    },
    getPdf() {

      const ecology = Cookie.get('ecology')

      netService({
        functionName: 'getStudentAllBlocks',

        project_id: this.project_id,
        account_id: this.account_id,
        team_code: this.team_code,
        ecology:ecology

      }).then(data => {
        this.show = true
        let temp = [];
        temp = data
        temp.map((item) => {
          if (item.block_attaches.length !== 0) {
            item.block_attaches.map((address) => {
              if (address.attach_address.substr(0, 7).toLowerCase() == "http://" || address.attach_address.substr(0, 8).toLowerCase() == "https://") {
                address.attach_address = address.attach_address
              } else {
                address.attach_address = "http://" + address.attach_address
              }
            })
          }
        })

        //重组一下数据结构
        let new_array = []
        var level_1_index = -1
        for (var i = 0; i < temp.length; i++) {
          let content = temp[i]
          var block_level = content['block_level']
          if (block_level == '1') {
            level_1_index = i
            var blocks = []
            blocks.push(content)
            var new_content = {
              id: content._id.$id,
              template_block: content['template_block'],
              viewType: 0,
              blocks: blocks
            }
            new_array.push(new_content)
          } else {
            var index = new_array.length - 1
            new_array[index]['viewType'] = 1
            new_array[index]['blocks'].push(content)
          }
        }
        this.allContent = new_array
      })
    },
    getProfile() {
      console.log('project_id:' + this.project_id)
      console.log('account_id:' + this.account_id)
      console.log('team_code:' + this.team_code)
      netService({
        functionName: 'getStudentProfile',
        project_id: this.project_id,
        account_id: this.account_id,
        team_code: this.team_code
      }).then(data => {
        console.log(data)
        this.studentInfo = data

      })
    },
    p(e) {

      
      this.$refs.btnClick.style.display = 'none';

      // e.path[1].style.display = 'none'
      document.title = this.studentInfo.project_name_en + '-' + this.studentInfo.account_nameEN + '-Team ' + this.team_code
      window.print()

      this.$refs.btnClick.style.display = 'flex';

      // e.path[1].style.display = 'flex'
    },

    printCert() {
      let subOutputRankPrint = document.getElementById('report_display');
      console.log(subOutputRankPrint.innerHTML);
      let newContent = subOutputRankPrint.innerHTML;
      let oldContent = document.body.innerHTML;
      document.body.innerHTML = newContent;
      window.print();
      window.location.reload();
      document.body.innerHTML = oldContent;
//            let bdhtml = window.document.body.innerHTML; //获取当前页的html代码
//            let sprnstr = "<!--startprint-->"; //设置打印开始区域
//            let eprnstr = "<!--endprint-->"; //设置打印结束区域
//            let prnhtml = bdhtml.substring(bdhtml.indexOf(sprnstr) + 18); //从开始代码向后取html
//            let prnhtmls = prnhtml.substring(0, prnhtml.indexOf(eprnstr)); //从结束代码向前取html
//            window.document.body.innerHTML = prnhtmls;
//            //window.document.body.innerHTML = bdhtml;
//            window.print();
//            window.document.body.innerHTML = bdhtml;
//            window.location.reload();
    },

    getJudgeComments() {
      netService({
        functionName: 'getJudgeComments',

        project_id: this.project_id,
        account_id: this.account_id,
        team_code: this.team_code,
        template_block_id:this.block_id
      }).then(data => {
        console.log(data)
        this.judgeComments = data


      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  color: white;
  width: 100%;
  height: 100%;
}

.max-container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

@page {
  margin: 40px 0 ;
}

@media print {
  .other-ele {
    display: none;
  }

  h1 {
    font-size: 50px
  }

  h2 {
    font-size: 40px
  }

  h3 {
    font-size: 30px
  }

  p {
    font-size: 16px;
  }
}
</style>
