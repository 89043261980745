<template>
    <div class="l-c">
        <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-position="right" label-width="142px" class="login-container" status-icon v-if="status==='login'">
            <h2 class="title" style="margin-bottom:20px">Project Guide System</h2>
            <p class="title" style="color:grey;margin-bottom:40px">The Project Guide System is <br>a professional management platform <br>for your working projects.</p>
            <!-- <el-radio v-model="radio" label="0">User</el-radio>
            <el-radio v-model="radio" label="1">Writer</el-radio> -->
            <el-form-item prop="account" label="用户名/Username:">
                <el-input type="text" v-model="ruleForm2.account" auto-complete="off" placeholder="用户名/Username" ></el-input>
            </el-form-item>
            <el-form-item prop="pass" label="密码/Password:">
                <el-input  v-model="ruleForm2.pass" auto-complete="off" placeholder="密码/Password" type="password" ></el-input>
            </el-form-item>
            
            <el-form-item label="登录类型/ID Type:" prop="loginType">
                <el-select v-model="value" placeholder="登录类型/ID Type" @change="change(value)">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
                </el-select>
            </el-form-item>
            <span v-if="!!error_text" style="color: red" class="error_text">{{error_text}}</span>
            <div class="forgetPwd" @click="toForget">忘记密码</div>
            <div style="text-align:center;margin-top:20px;margin-bottom:20px">
                <el-button type="primary" style="width:78%;" @click="submit" :loading="logining">登录/Login</el-button>
            </div>
        </el-form>
        <div class="login-container" v-if="status==='forget'">
            <h3 class="title">忘记密码</h3>
            <el-form style="margin-top:30px" label-position="right" label-width="80px">
                <el-form-item label="账号邮箱">
                    <el-input v-model="forgetPwd.email"></el-input>
                </el-form-item>
                <el-form-item label="账号类型">
                    <el-select v-model="forgetPwd.type">
                        <el-option value="student" label="学生"></el-option>
                        <el-option value="teacher" label="老师"></el-option>
                    </el-select>
                </el-form-item>
                <div style="text-align:center;margin-top:30px;margin-bottom:20px">
                    <el-button @click="toLogin">返回登录</el-button>
                    <el-button @click="sendNewPwd" type="primary" :loading="forgetLoading">下一步</el-button>
                </div>
            </el-form>
        </div>
        <div class="login-container" v-if="status==='land'">
            <h3 class="title" style="margin-top:20px">新密码已发送至您的邮箱，请注意查收。</h3>
            <div style="text-align:right;margin-top:30px;margin-bottom:20px">
                <el-button @click="toLogin">返回登录</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { login, loginByEducator, loginByContact, sendNewPwd } from '../service.js'
import Cookie from 'js-cookie'
// import JsPDF from 'jspdf'
// var doc = new JsPDF()

// doc.text('Hello world!', 10, 10)
// doc.save('a4.pdf')
export default {
  name: 'Login',
  mounted() {
    // if(Cookie.get('studentid')) {
    //   this.$router.push('/classlist')
    // }
  },
  data() {
    return {
      error_text: '',
      logining: false,
      admin: false,
      ruleForm2: {
        account: '',
        pass: '',
      },
      rules2: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          //{ validator: validaePass }
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          //{ validator: validaePass2 }
        ],
        loginType: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      options: [{
        value: '学生',
        label: '学生/Student'
      },{
        value: '导师',
        label: '导师/评委 Adviser/Judge'
      },{
        value: '老师',
        label: '学校指导老师/Teacher'
      },{
        value: '管理员',
        label: '管理员/Administrator'
      }],
      value: '',
      status:'login',
      forgetPwd:{
          type:'student',
          email:''
      },
      forgetLoading:false
    };
  },
  methods: {
    change(value) {
      if(value === '管理员') {
        this.admin = true
        // this.ruleForm2.account = 'admin'
        // this.ruleForm2.pass = 'yiquanTodo'
      } else {
        this.admin = false
      }
    },
    submit(){
      if(this.ruleForm2.account === '' || this.ruleForm2.account === '' || this.value === ''){
        this.$message({
            message: '某些表单没有填写请填写后提交~',
            type: 'error',
            duration: 1500,
            
          });
      } else {
        this.logining = true
        if(this.value === '学生'){
          login({
            student_pwd: this.ruleForm2.pass,
            student_email: this.ruleForm2.account,
          }).then((data) => {
            console.log('account_id')
            console.log(data)
            if(data.status === 1) {
              Cookie.set('type', 'student')

              this.error_text = ''
              Cookie.set('studentid', data.student_info._id.$id)

              Cookie.set('accountid', data.student_info.account_id)

              console.log(666,data.is_ecology)
              if (data.is_ecology != undefined){
                Cookie.set('ecology', data.is_ecology)
              }

              this.$message({
                message: '登录成功~',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.$store.commit('setUserValue', data.student_info)
                  this.$router.push('/sessionList')
                }
              });

            } else {
              this.error_text = '邮箱或者密码错误~'
            }
            // console.log(data === 1)
            this.logining = false
            
          })
        } else if(this.value === '导师'){
          loginByEducator({
            educator_pwd: this.ruleForm2.pass,
            educator_email: this.ruleForm2.account,
          }).then((data) => {
            // console.log(data)
            if(data.status === 1) {
              this.error_text = ''
              Cookie.set('studentid', data.educator_info._id.$id)
              Cookie.set('type', 'educator')
              this.$message({
                message: '登录成功~',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.$store.commit('setUserValue', data.educator_info)
                  this.$router.push('/classlist')
                }
              });

            } else {
              this.error_text = '邮箱或者密码错误~'
            }
            // console.log(data === 1)
            this.logining = false
            
          })
        } else if (this.value === '老师') {
          loginByContact({
            contact_pwd: this.ruleForm2.pass,
            contact_email: this.ruleForm2.account,
          }).then((data) => {
            if(data.status === 1) {
              this.error_text = ''
              Cookie.set('studentid', data.contact_info._id.$id)
              Cookie.set('accountid', data.contact_info.account_id)
              Cookie.set('type', 'contact')
              this.$message({
                message: '登录成功~',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.$store.commit('setUserValue', data.contact_info)
                  this.$router.push('/accountlist')
                }
              });

            } else {
              this.error_text = '邮箱或者密码错误~'
            }
            // console.log(data === 1)
            this.logining = false
          })
        } else if (this.value === '管理员') {
          if(this.ruleForm2.account =='admin' && 
           this.ruleForm2.pass == 'yiquanTodo'){
            Cookie.set('user_name', '管理员')
            Cookie.set('studentid', 'admin')
            Cookie.set('type', 'admin')
            this.$router.push({ path: 'AccountList', query: { from : 'admin' }})
          }else {
              this.error_text = '用户或者密码错误~'
            }
            this.logining = false
        }
      }
      
      // this.$router.push('/classlist')
    },
    toForget(){
        this.status = 'forget'
        this.forgetPwd={
          type:'student',
          email:''
      }
    },
    toLogin(){
        this.status = 'login'
    },
    sendNewPwd(){
        if(this.forgetPwd.email===''){
            this.$message.warning('邮箱不能为空！')
            return
        }
        this.forgetLoading = true
        sendNewPwd(this.forgetPwd.email,this.forgetPwd.type).then(res=>{
            this.forgetLoading = false
            if(res.data.code===0){
                this.status = 'land'
            }else{
                this.$message.warning(res.data.msg)
            }
        })
    }
  },
  computed: {
    radio: {
      get(){
        return this.$store.state.isWriter
      },
      set(value) {
        this.$store.commit('toggleWriter', value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .l-c{
    width: 100%;
    position: relative;
    background: url('http://area.sinaapp.com/bingImg/');
    background-position: 0 0; 
    .login-container {
      /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
      -webkit-border-radius: 5px;
      border-radius: 5px;
      -moz-border-radius: 5px;
      background-clip: padding-box;
      position: absolute;
      top:43%;
      left:50%;
      transform: translate(-50%,-50%);
      width: 360px;
      padding: 35px 35px 15px 35px;
      background: #fff;
      border: 1px solid #eaeaea;
      box-shadow: 0 0 25px #cac6c6;
      .title {
        margin: 0px auto 10px auto;
        text-align: center;
        color: #505458;
      }
      .remember {
        margin: 0px 0px 35px 0px;
      }
      .el-radio {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
  .error_text{
    color: red;
    // margin-bottom: 10px; 
  }
  .forgetPwd{
      text-align: right;
      font-size: 12px;
      color: #409EFF;
      cursor: pointer;
  }
  .forgetPwd:hover{
      opacity: 0.8;
  }
</style>
