<template>
  <div class="Home">
    <Head />
    <div class="templateHead">
      <h3>{{template_name}}
          <el-button v-if="project_id==='6087cb92a4640b8b5a8b4588' ||  project_id==='6279fa81a4640b5b678b4597'" type="primary" @click="sendEmail" style="margin-left:30px">我已完成简历填写，确认提交</el-button>
          <!-- <span style="font-size:16px;color:#666;font-weight: normal;" v-if="forbid_edit">（因后台设置，暂且无法修改）</span> -->
      </h3>

      
      <el-button v-if="ecology == 'true' && draft_status == 0" class="draft" @click="updateDraft(0)">提交选题</el-button>
      <el-button v-if="ecology == 'true' && draft_status == 1" class="draft" @click="updateDraft(1)">提交初稿</el-button>
      <el-button v-if="ecology == 'true' && draft_status == 2" class="draft" @click="updateDraft(2)">提交二稿</el-button>
      <el-button v-if="ecology == 'true' && draft_status == 3" class="draft" @click="updateDraft(3)">提交终稿</el-button>
      <el-button v-if="ecology == 'true' && draft_status == 4" class="draft" >已提交</el-button>

      <el-button class="pdf" @click="getPdf()">汇总</el-button>
      <p>{{template_description}}</p>
    </div>
    <el-container style="height: 100%; border: 1px solid #eee;height:calc(100% - 60px)" >
      <el-aside width="20%" style="background-color: rgb(238, 241, 246)">
        <!-- <el-menu :default-openeds="['1', '3']" @select="onselect" @open="onopen" @close="onclose">
          <el-submenu index="1" title="试试看" >
              <template slot="title"><i class="el-icon-message"></i>导航一</template>
              <el-menu-item index="1-1">选项1</el-menu-item>
              <el-menu-item index="1-2">选项2</el-menu-item>
            
          </el-submenu>
          <el-submenu index="2">
            <template slot="title"><i class="el-icon-menu"></i>导航二</template>
            <el-menu-item-group>
              <template slot="title">分组一</template>
              <el-menu-item index="2-1">选项1</el-menu-item>
              <el-menu-item index="2-2">选项2</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group title="分组2">
              <el-menu-item index="2-3">选项3</el-menu-item>
            </el-menu-item-group>
            <el-submenu index="2-4">  
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项4-1</el-menu-item>
            </el-submenu>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title"><i class="el-icon-setting"></i>导航三</template>
            <el-menu-item-group>
              <template slot="title">分组一</template>
              <el-menu-item index="3-1">选项1</el-menu-item>
              <el-menu-item index="3-2">选项2</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group title="分组2">
              <el-menu-item index="3-3">选项3</el-menu-item>
            </el-menu-item-group>
            <el-submenu index="3-4">
              <template slot="title">选项4</template>
              <el-menu-item index="3-4-1">选项4-1</el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu> -->
        <el-tree :data="menuItems" :props="defaultProps" @node-click="handleNodeClick" :render-content="renderContent"></el-tree>
      </el-aside>
      
      <el-container >
        
        
        <el-main>
          <!-- <el-table :data="tableData">
            <el-table-column prop="date" label="日期" width="140">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="address" label="地址">
            </el-table-column>
          </el-table> -->
          <el-container class="title">
            {{content.block_name || '请选择左侧模块~'}}
            <span class="title-description" v-if="content.content_type === 'VIDEO'"> (本课程有{{content_quiz.length}}道随堂练习)</span>
            <span class="tip" v-if="content.block_require=='no'">unnecessary</span>
            <span class="tip" v-if="content.block_require=='yes'">required</span>
          </el-container>

          <div v-if="content.block_name" class="content_instruction">{{content.block_description || ''}}</div>
          <div v-if="content.block_name && checklist.length" class="check-box">
            <h3 v-if="checklist.length">Self-check list for this part:</h3>
            <div v-for="(item, index) in checklist" :key="item._id.$id">
              <el-checkbox :disabled="forbid_edit"  :label="index" :key="item.check_content" v-model="item.check_status" @change="handleCheckedCitiesChange(item)"><div class="check-box-item"><span style="background:#DCDFE6;padding:1px 8px;margin-right:4px;border-radius:4px;color:#606266">{{item.check_type}}</span>{{item.check_content}}</div></el-checkbox>
            </div>
          </div>

          <div v-if="content.block_type === 'HTML'" style="height:calc(100% - 60px)">
            <div v-if="content.html_text" v-html="content.html_text"></div>
            <iframe v-if="!content.html_text && content.pdf_url" :src="content.pdf_url" width="100%" height="100%">

            This browser does not support PDFs. Please download the PDF to view it: <a :href="content.pdf_url">Download PDF</a>

            </iframe>


            <div v-if="!content.html_text && !content.pdf_url" style="margin-top:20px">Coming soon.</div>
          </div>
          <div v-if="content.block_type === 'VIDEO'">
            <div>
              <video :src="content.video_url" id="videoPlay" controls="controls" class="video" oncontextmenu="return false;" v-show="!isBeginQuiz" @timeupdate="timeupdate" @ended="videoEnd">您的浏览器不支持 video 视屏播放。</video>
              <Quiz :studentId="studentId" :quizs="quizs" :onDone="onQuizDone" />
            </div>
          </div>
          <el-dialog
            title="视频播放"
            :visible.sync="dialogVisible"
            width="60%"
            center
            @close="closeVideo">
            <div class="video_container">
              <video class="video" :src="address" controls="controls" width="80%" height="80%"></video>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeVideo">取 消</el-button>
              <el-button type="primary" @click="closeVideo">确 定</el-button>
            </span>
          </el-dialog>

          <el-collapse v-model="activeNames" v-show="appendix">
            <el-collapse-item title="参考资料" name="appendix">
              <div class="attach_arr">
                <div class="appendix" >
                  <div style="text-align: center;margin-left:10px" v-for="item in attach_arr" :key="item._id.$id">
                    <div style="text-align:center;margin-top:30px" v-if="item.attach_format !== 'video'">
                      <img class="attach-img" :src="item.attach_avatar_url? 'http://'+item.attach_avatar_url : 'https://i1.wp.com/www.questionpro.com/blog/wp-content/uploads/2015/03/primary-test-it.png?w=293&ssl=1'" alt="">
                    </div>
                    <a class="attach" v-if="item.attach_format !== 'video'" target="_blank" :href="item.attach_address" :download="item.attach_name">{{ item.attach_name }}</a>
                    <div style="text-align:center;margin-top:30px" v-if="item.attach_format === 'video'" @click="playVideo(item.attach_address)">
                      <img class="attach-img" :src="item.attach_avatar_url? 'http://'+item.attach_avatar_url : 'https://i1.wp.com/www.questionpro.com/blog/wp-content/uploads/2015/03/primary-test-it.png?w=293&ssl=1'" alt="">
                      <a>{{ item.attach_name }}</a> 
                    </div>
                    <!-- <div v-if="item.attach_format === 'video'" @click="playVideo(item.attach_address)"> -->
                     <!-- <img class="attach-img" :src="item.attach_avatar_ur || 'https://i1.wp.com/www.questionpro.com/blog/wp-content/uploads/2015/03/primary-test-it.png?w=293&ssl=1'" alt=""> -->
                      <!-- <a>{{ item.attach_name }}</a> 
                    </div> -->
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-dialog
            title="视频播放"
            :visible.sync="videoDialogVisible"
            width="60%"
            center
            @close="closeVideo">
            <div class="video_container">
              <video class="video" :src="videoAddress" controls="true" controlslist="nodownload" width="80%" height="80%"></video>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeDialog">取 消</el-button>
              <el-button type="primary" @click="closeDialog">确 定</el-button>
            </span>
          </el-dialog>
          <el-collapse v-model="activeNames" v-show="content_attach.length !== 0">
            <el-collapse-item title="附件：" name="1" v-show="content_attach.length !== 0">

              <div class="progress" v-if="proVisible">
                <el-progress :percentage='process'></el-progress>
              </div>
              <ul v-for="(item,index) in content_attach" :key="item._id.$id">
                <span class="attachType">{{item.attach_type}}:</span>
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="item.attach_key">
                  <a class="attach" target="_blank" :href="item.attach_address" :download="item.attach_name" v-if="
                  !item.edit" slot="reference">{{item.attach_name }}  </a>
                </el-popover>
                <input type="text" v-model="item.attach_name " v-if="item.edit" />
                <a class="deleteAttach" @click="deleteAttach(index)" v-if="!forbid_edit">删除</a>
                <a class="deleteAttach" @click="editAttach(index)" v-if="!item.edit && !forbid_edit">编辑</a>
                <a class="deleteAttach" @click="confirmAttach(index)" v-if="item.edit && !forbid_edit">确认</a></ul>

              <!-- <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div> -->
            </el-collapse-item>

          </el-collapse>
          <div> 
            <el-select v-model="update_block_content[0]" placeholder="请选择" v-if="content.block_format=='select'">
              <el-option
                v-for="item in selectOptions "
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>

          </div>
          <div>
            <div style="margin-top:20px"  v-if="content.block_format==='input'">
              <div v-for="(item, index) in update_block_content" :key="index" >
                  <el-input style="margin-top:10px;" type="text" v-model="update_block_content[index]" ></el-input>
                  <div>
                    <el-select @change="updateCheck" v-if="content.block_review==='yes' && update_check_content && lookType!=='student'" v-model="update_check_content[index]">
                        <el-option value="待验证"></el-option>
                        <el-option value="已验证"></el-option>
                    </el-select>
                    <el-tag style="margin-left:20px" dark :type="update_check_content[index]==='已验证'?'success':'primary'" v-if="content.block_review==='yes' && update_check_content && lookType==='student'">{{update_check_content[index]}}</el-tag>
                  </div>
              </div>
              <el-button style="margin-top:20px;margin-left:0" v-if="(lookType == 'student' || lookType == 'admin') && !forbid_edit" :loading="s_a" @click="submitBlock">提交</el-button>
            </div>
            <div style="margin-top:20px"  v-if="content.block_format==='textarea' ||content.block_format==='pdf'|| content.block_format==='select'">
                <span style="margin-bottom:10px;"  v-if="lookType != 'student' ">学生作答：</span>

                <div v-for="(item, index) in update_block_content"  :key="index" >
                    <el-input style="margin-top:10px;" type="textarea" v-model="update_block_content[index]"></el-input>
                    <div style="margin-top:10px">
                        <el-button @click="removeInput(index)" v-if="lookType == 'student' && !forbid_edit">去除输入框</el-button>
                        <el-select style="margin-left:10px" @change="updateCheck" v-if="content.block_review==='yes' && update_check_content && lookType!=='student'" v-model="update_check_content[index]">
                            <el-option value="待验证"></el-option>
                            <el-option value="已验证"></el-option>
                        </el-select>
                        <el-tag style="margin-left:20px" dark :type="update_check_content[index]==='已验证'?'success':'primary'" v-if="content.block_review==='yes' && update_check_content && lookType==='student'">{{update_check_content[index]}}</el-tag>
                    </div>
                </div>
              <el-button style="margin-top:20px;display:block" type="primary" v-if="lookType == 'student' && content.multi_allowed === 'yes' && !forbid_edit" @click="addTextarea">添加输入框</el-button>
              

              <!-- <input class="file" name="file" type="file"  accept="*" @change="getFile" v-if="lookType == 'student'" /><br/> -->
              <div class="btn" v-if="content.block_format!='select'">
                <el-button type="primary" @click="centerDialogVisible = true" :loading="upLoading" v-if="!forbid_edit">添加附件</el-button>
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :total="past_content_array.length+1"
                :page-size="1"
                style="margin-top:10px"
                v-if="lookType != 'student'"
                @current-change="changePage"
                >
              </el-pagination>
              <el-dialog
                title="上传选项"
                :visible.sync="centerDialogVisible"
                width="40%"
                center>
                <div class="select">
                上传类型:
                <el-select v-model="selectType" placeholder="选择上传类型">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                </div>
                <div>
                  附件名称：
                  <el-input v-model="videoName" placeholder="请输入附件名称"></el-input>
                  <p v-if=" selectType == 'file' || selectType == 'gongzhonghao' || selectType == 'website' ">附件地址：</p>
                  <el-input v-model="videoPath" placeholder="请输入附件地址" v-if=" selectType == 'file' || selectType == 'gongzhonghao' || selectType == 'website'"></el-input>
                </div>
                <input class="file" name="file" type="file"  accept="*" @change="getFile" v-if=" selectType == 'pdf' || selectType == 'image'" />
                <input class="file" name="file" type="file"  accept="*" @change="getVideoFile" v-if=" selectType == 'video'" />
                <br/>
                <div class="intro">
                附件说明:
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入附件说明"
                  style="margin-top:10px;"
                  v-model="intro">
                </el-input>
                </div>
                <span slot="footer" class="dialog-footer">
                  <span v-if=" selectType != 'video'" style="font-size:12px;color:#ccc;margin-right:20px">注意上传大小不能超过5MB</span>
                  <span v-if=" selectType == 'video'" style="font-size:12px;color:#ccc;margin-right:20px">注意上传大小不能超过1GB</span>
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="uploadBlock">确 定</el-button>
                </span>
              </el-dialog>

              <!-- <div class="select">
                上传类型:
                <el-select v-model="selectType" placeholder="选择上传类型">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <el-input v-model="videoName" placeholder="请输入视频名称" v-if="lookType == 'student' && selectType == 'video'"></el-input>
              <el-input v-model="videoPath" placeholder="请输入视频地址" v-if="lookType == 'student' && selectType == 'video'"></el-input>
              <input class="file" name="file" type="file"  accept="*" @change="getFile" v-if="lookType == 'student' && (selectType == 'pdf' || selectType == 'image')" /><br/>
              <div class="intro">
                附件说明:
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入附件说明"
                  style="margin-top:10px;"
                  v-model="intro">
                </el-input>
              </div> -->
              <el-button style="margin-top:20px;margin-left:0" v-if="(lookType == 'student' || lookType == 'admin') && !forbid_edit" :loading="s_a" @click="submitBlock">提交</el-button>
              
              <span style="margin-left:10px;color: #aaa;"  >最后修改时间：{{latest_save_time}}</span>
            </div>

            
            
          </div>
          <div v-if="content.block_name">
            <div class="addCommets" v-if="lookType !== 'student' ">
                <el-input style="margin-top:10px;"   type="textarea" v-model="educator_comment" ></el-input>
                <el-button style="margin-top:20px" :disabled="!isAnswer && content.block_format !== 'blank'" @click="submitComment">添加评论</el-button>

            </div>
            <div class="comments">
                <!-- <div style="margin-bottom:20px">我的内容：{{my_block}}</div> -->
                老师评论：{{comments.length === 0 ? '无': ''}}
                <div class="editComments" v-for="(item) in comments" :key="item._id.$id" style="margin-top: 10px;">
                  <p v-if="!item.edit">{{item.comment_text}}</p>
                  <el-input  v-if="item.edit" style="margin-top:10px;" type="textarea" v-model="item['comment_text']" ></el-input>

                  <el-button size="mini" 
                  
                  @click="editComment(item)"
                  v-if="item.educator_id == studentid && !item.edit"
                  >修改</el-button>
                  <el-button size="mini" 
                  
                  @click="editCommentComfirm(item)"
                  v-if="item.educator_id == studentid && item.edit"
                  >确认</el-button>
                  <el-button 
                  size="mini" 
                  
                  :disabled="!isAnswer" 
                  v-if="item.educator_id == studentid || lookType=='admin' "
                  @click="delComment(item._id.$id)">删除</el-button>

                </div>
            </div>
            </div>
          
          


        </el-main>
        <!-- <div class="console">
            console
        </div> -->
      </el-container>
      
    </el-container>
    
  </div>
</template>

<script>
import {  getContent, getTemplate,
    updateStudentBlock, getStudentBlock,
    netService,
    upload,
    uploadStaticBlockAttach,
    sendEmail,
    updateStudentBlockCheck,judgeForbidStatus,getQiniuToken,
    getDraftStatus,
    addDraftStatus
} from '../service.js'
import Cookie from 'js-cookie'

import Head from './Head'
import Quiz from './Quiz'
import JsPDF from 'jspdf'

import * as qiniu from 'qiniu-js'

export default {
  name: 'Home',
  props: {
    msg: String
  },
  components: {
    Head,
    Quiz,
  },
  mounted() {
    this.studentid = Cookie.get('studentid')
    this.project_product =  this.$router.currentRoute.query.project_product
    this.template_id = this.$router.currentRoute.query.template_id
    this.project_id = this.$router.currentRoute.query.project_id
    this.application_team_code = this.$router.currentRoute.query.application_team_code
    this.accountid = this.$router.currentRoute.query.accountid
    this.now_block_account_id = this.$router.currentRoute.query.accountid
    this.lookType = Cookie.get('type')
    this.studentAccountId =  Cookie.get('accountid')
    this.ecology = Cookie.get('ecology')

    // getProjectTeamcodes({
    //   project_id: this.project_id
    // }).then(data => {
    //   console.log(data)
    // })

    // this.studentId = Cookie.get('studentid')
    getTemplate({template_id: this.template_id}).then(data => {
      this.contents = data.block_contents
      this.menuItems = this.handleList(data.block_contents) 
      this.template_name = data.template_info.template_name
      this.template_description = this.template_info.template_description
    })

    this.judgeForbid();
    this.getDraftStatus();
    
    // getContents({session_id: this.session_id,student_id:this.studentId}).then((data) => {
    //   // console.log(data)
    //   this.menuItems = this.handleList(data) 
    //   // console.log(this.menuItems)
    // })
  },
  methods: {
    //判断pg_forbid_status 禁止修改
    judgeForbid(){
      judgeForbidStatus(this.project_id,this.accountid,this.studentid,this.application_team_code).then(res=>{
        if(res.data){
          this.forbid_edit = res.data
        }
      })
    },
    getDraftStatus(){
      getDraftStatus(this.project_id,this.accountid,this.application_team_code).then(res=>{
        if(res.data){
          this.draft_status = res.data
        }
      })
    },
    updateDraft(index){

      var  infoStr = '';

      if (index == 0){
        infoStr = '每个项目都需要通过选题评审，请确认你们已经按要求填写Project Title。提交申请后，导师将对你们提交的选题进行审核。是否确认提交？'
      }
      else if (index == 1 || index == 2){
        infoStr = '每个项目有初稿和二稿共两次点评机会，提交申请后，导师将对你们提交的内容进行点评。是否确认提交？'
      }else{
        infoStr = '提交终稿后，你们将不能再对项目进行编辑。你们的项目将进入评审阶段。是否确认提交终稿？'
      }


      this.$confirm(infoStr, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.addDraft()
      }).catch(() => {
               
      });
      
    },
    addDraft(){
      addDraftStatus(this.project_id,this.accountid,this.application_team_code).then(res=>{
        this.getDraftStatus()
        this.judgeForbid();

      })
    },
    
    closeVideo() {
      this.dialogVisible = false
      this.address = ''
    },
    playVideo(address) {
      this.dialogVisible = true
      this.address = address
    },
    openDialog(e) {
      this.videoDialogVisible = true
      this.videoAddress = e
    },
    submitBlock(){


      if (this.content.max_length) {
        // if (
        //   this.update_block_content.some(
        //     (item) => item.length > this.content.max_length
        //   )
        // ) {
        //   this.$message.error(`最大字数限制为${this.content.max_length}`);
        //   return;
        // }

        let canSubmit = true;
        for (let i = 0; i <= this.update_block_content.length; i++){
         
          let item  = this.update_block_content[i]
          console.log(12345,item)
          if (item){
            let csvarray = item.split(" ");
  
            if (csvarray.length > this.content.max_length){
              canSubmit = false
            }
          }

          
        }

        if (canSubmit == false){
            this.$message.error(`最大字数限制为${this.content.max_length}`);
            return;
        }


      }
      if (this.content.min_length) {
        // if (
        //   this.update_block_content.some(
        //     (item) => item.length < this.content.min_length
        //   )
        // ) {
        //   this.$message.error(`最小字数限制为${this.content.min_length}`);
        //   return;
        // }

        let canSubmit = true;

        for (let i = 0; i <= this.update_block_content.length; i++){
         
          let item  = this.update_block_content[i]
          console.log(12345,item)
          if (item){
            let csvarray = item.split(" ");
  
            if (csvarray.length < this.content.min_length){
              canSubmit = false
            }
          }

          
        }

        if (canSubmit == false){
            this.$message.error(`最小字数限制为${this.content.min_length}`);
            return;
        }

      }
      this.s_a = true
      console.log(this.content,this.application_team_code)
      
      let param = {
        team_code: this.application_team_code,
        project_id: this.project_id,
        account_id: this.lookType == 'student' ?this.now_block_account_id:this.accountid,
        block_id: this.content.id,
        block_content: this.update_block_content
      }
      if(this.lookType == 'student'){
        param.student_id = this.studentid
      }

      param.ecology = this.ecology

      updateStudentBlock(param).then(data => {
        if(data== null){
          // console.log(12344444,data)
          if (data == false){
              this.$message({
                message: '提交失败~',
                type: 'error'
              });
          }else{
              this.$message({
                message: '上传成功~',
                type: 'success'
              });
          }
          
          this.s_a = false
                  
          this.getSB()
        }
      })
    },
    getChecksByBlock(block_id){
        netService({
          functionName: 'getChecksByBlock',
          block_id,
          team_code: this.application_team_code,
          student_id: this.studentAccountId,
          account_id: (this.lookType == 'student' ?this.now_block_account_id:this.accountid),
        }).then(data => {
          console.log(data,'@!!!!!!')
          this.checklist = data
        })
    },
    handleCheckedCitiesChange(value){
      this.switchCheckBlockByStudent(value._id.$id)
    },
    switchCheckBlockByStudent(check_id){
        netService({
          functionName: 'switchCheckBlockByStudent',
          team_code: this.application_team_code,
          student_id: this.studentAccountId,
          account_id: (this.lookType == 'student' ?this.now_block_account_id:this.accountid),
          project_id:this.project_id,
          check_id
        }).then(data => {
          console.log(data,'@!!!!!!')
          // this.checklist = data
        })
    },
    uploadBlock() {
      if (this.content.forbid_upload && this.content.forbid_upload==='yes') {
        this.$message.error("该模块不允许上传附件");
        this.upLoading = false;
        return;
      }


      if (this.videoName === ''){

          this.$message({
            message: '请填写附件名称~',
            type: 'error'
          });
        return
      }
      this.upLoading = true


      if (this.selectType === 'video'){
        this.file && this.qiniuupload()
        this.centerDialogVisible = false
      }
          
      if(this.selectType === 'pdf' || this.selectType === 'image'){
        this.file && this.uploadFile()
        this.centerDialogVisible = false
      }
      if(this.selectType === 'file' || this.selectType === 'gongzhonghao' || this.selectType === 'website') {


        let param = {
          functionName: 'uploadStaticBlockAttach',
          block_id: this.content.id,
          project_id: this.project_id,
          account_id:(this.lookType == 'student' ?this.now_block_account_id:this.accountid),
          team_code: this.application_team_code,
          attach_name: this.videoName,
          attach_type: this.selectType,
          attach_address: this.videoPath,
          attach_intro: this.intro,
          ecology:this.ecology
        }
        if(this.lookType == 'student'){
            param.student_id = this.studentid
        }

        netService(param).then(data => {
          console.log(1111,data)
          this.centerDialogVisible = false
          this.upLoading = false
          if (data == false){
              this.$message({
                message: '提交失败~',
                type: 'error'
              });
          }else{
              this.$message({
                message: '上传成功~',
                type: 'success'
              });
          }
          this.reload();

        })
      }
    },

    qiniuupload() {  
       //file是选择的文件对象
      let _this = this
      this.proVisible = true
      this.process = '0'

      var videofile = this.file
      return new Promise((resolve, reject) => {
          getQiniuToken().then((res) => {    
              let uptoken = res.data.uptoken;
              let key = Math.random(1000) + videofile.name;   //这是上船后返回的文件名，这里为了避免重复，加上了随机数
              var config = {
                  useCdnDomain: true,        //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                  region: qiniu.region.z0,      //选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域。  我这里是华东区
                  domain: "http://mainbucket.learningfirst.cn", //配置好的七牛云域名  如   https://cdn.qniyun.com/
                  chunkSize: 100,     //每个分片的大小，单位mb，默认值3
                  forceDirect: true    //直传还是断点续传方式，true为直传
              };
              var putExtra = {
                  fname: videofile.name,    //文件原始文件名
                  params: {},
                  mimeType: [] || null
                  // ...config,
              };
              var observable = qiniu.upload(videofile, key, uptoken, putExtra, config);
              observable.subscribe({
                  next: (result) => {
                      //主要用来展示进度

                      let loaded = result.total.loaded
                      let total = result.total.size

                      console.log(11111,((loaded/total) * 100).toFixed(1));
                      // console.log(111112222,total);

                      _this.$nextTick(() => {
                        _this.process = parseFloat(((loaded/total) * 100).toFixed(1))
                      })
                  },
                  error: (err) => {
                      //上传错误后触发
                      console.log(222222,err);

                      this.proVisible = false
                      this.upLoading = false

                      reject(err)
                  },
                  complete: (result) => {
                      //上传成功后触发。包含文件地址。
                      console.log(33333,result);
                      resolve(result)

                      var resultUrl = 'http://mainbucket.learningfirst.cn/' + result.key
                      let param = {
                        functionName: 'uploadStaticBlockAttach',
                        block_id: this.content.id,
                        project_id: this.project_id,
                        account_id:(this.lookType == 'student' ?this.now_block_account_id:this.accountid),
                        team_code: this.application_team_code,
                        attach_name: this.videoName,
                        attach_type: this.selectType,
                        attach_address: resultUrl,
                        attach_intro: this.intro,
                        ecology:this.ecology

                      }
                      if(this.lookType == 'student'){
                          param.student_id = this.studentid
                      }
                      netService(param).then(data => {
                        console.log(data)
                        this.centerDialogVisible = false
                        this.upLoading = false
                        this.proVisible = false
                        if (data == false){
                            this.$message({
                              message: '提交失败~',
                              type: 'error'
                            });
                        }else{
                            this.$message({
                              message: '上传成功~',
                              type: 'success'
                            });
                        }
                        this.getBlockAttaches()

                      })
                  },
              });
          });
      })
    },

    submitComment(){
      netService({
        functionName: 'updateBlockComment',
        project_id:this.project_id,
        team_code:this.application_team_code,
        account_id:this.accountid,
        educator_id: this.studentid,
        student_block_id: this.student_block_id,
        comment_text: this.educator_comment,
        ecology:this.ecology

      }).then((data => {
        console.log(data)
        this.getSB()
      }))
    },
    renderContent(h, { node, data }) {
      // console.log(2222,data)
      // <i class="el-icon-success"></i>
      //complete_status ? 没找到这个值
        return (
          <span class="el-tree-node__label">
            <span title={node.label}>{node.label}{node.complete_status}</span>
          </span>);
      },
      handleNodeClick(data) {
        
        this.contents.map((item) => {
          if(item.block_info.block_name === data.block_info.block_name) {
            if(item.attach_arr.length === 0) {
              this.appendix = false
              this.attach_arr = []
            } else {
              this.appendix = true
              this.attach_arr = item.attach_arr
              
            }
          }
        })
        if( data.block_info.block_type === 'VIDEO'){
          getContent({content_id: data.content_info.id}).then((data) => {
            // console.log(data.content_quiz)
            this.quizsByTimes = {}
            this.isBeginQuiz = false
            this.quizs = []
            this.videoDone = false        
            const quizsByTimes = this.quizsByTimes
            this.content_quiz = data.content_quiz
            data.content_quiz.map((v) => {
              if(quizsByTimes[+v.quiz_position]) {
                quizsByTimes[v.quiz_position].push(v)
              } else {
                quizsByTimes[v.quiz_position] = [v]
              }
            })
            // this.quizs = [...data.content_quiz]
            // quizsByTimes
          })
          
        }
        if(data.block_info.block_format === 'select'){
         console.log(data, 'xxxxxxx')
         
          this.selectOptions = data.block_info.block_options_array
        }
        // content important!!!!!!!
        this.content = data.block_info

        this.getBlockAttaches()
        // this.block_id = data._id.$id
        this.getSB()
        this.getChecksByBlock(this.content.id)
        // getContent({content_id: data.content_info.content_id}).then((i) => {
        // })
      },
      
      getBlockAttaches(){
        netService({
          functionName: 'getBlockAttaches',
          block_id: this.content.id,
          project_id: this.project_id,
          account_id:(this.lookType == 'student' ?this.now_block_account_id:this.accountid),
          team_code: this.application_team_code
        }).then(data => {
          console.log(data)
          this.s_a = false
          this.content_attach = data
          this.content_attach.map((item) => {
            if(item.attach_address.substr(0,7).toLowerCase() == "http://" || item.attach_address.substr(0,8).toLowerCase() == "https://") {
              item.attach_address = item.attach_address
            } else {
              item.attach_address = "http://" + item.attach_address
            }
          })
        })
      },
      getSB(){
         const id = (this.lookType == 'student' ? 'student_id' : 'account_id')
         console.log(id,this.accountid)
         netService({
           functionName: 'getStudentBlock',
          team_code: this.application_team_code,
          project_id: this.project_id,
          [id]: (this.lookType == 'student' ?this.studentid:this.accountid),
          block_id: this.content.id,
          ecology:this.ecology

        
          }).then(data=>{
            console.log(data)
            // this.now_block_account_id = data.account_id
            this.update_block_content = data.block_content.length === 0 ? [''] : data.block_content
            if(data.check_content){
                this.update_check_content = data.check_content
            }else{
                this.update_check_content = new Array(data.block_content.length===0?1:data.block_content.length).fill('待验证');
            }
            this.block_content = data.block_content
            if(data.check_content){
                this.check_content = data.check_content
            }
            this.comments = data.block_comments.map(v => {
              v.edit = false
              return v
            })
            const date = new Date(data.latest_save_time*1000)
            this.latest_save_time = data.latest_save_time ? date.toLocaleDateString() + ' '+date.toLocaleTimeString() : '没有记录~'
            this.now_save_time = data.latest_save_time
            this.isAnswer = data._id ? true: false
            this.student_block_id = data._id.$id

            
            if(this.lookType != 'student'){
              if(data.past_content_array){
                this.past_content_array = data.past_content_array
              }
            }
          })
      },
      handleList(list) {
        let result = []
        list.map(v => {
          this.dfs(result, v)
        })
        return result
      },
      dfs(result, v) {
        // console.log(v, result)
        if (+v.block_info.block_level === 1) {
          result.push({
            label: v.block_info.block_name,
            children: [],
            complete_status: v.complete_status,
            block_info: {
              ...v.block_info,
              id: v._id.$id,
            }
          })
        } else {
          const len = result.length
          const l = +v.block_info.block_level
          v.block_info.block_level = l - 1
          v.block_info.block_raw_level = l
          this.dfs(result[len-1].children, v)
        }
      },
      onQuizDone() {
        this.isBeginQuiz = false
        this.quizs = []
        this.videoDone || this.videoElement.play()
        this.videoDone = false
      },
      timeupdate(event) {
        const currentTime = event.target.currentTime;//获取当前播放时间
        this.videoElement = event.target
        // console.log(currentTime,this.quizsByTimes[~~currentTime],this.quizsByTimes);//在调试器中打印
        if(this.quizsByTimes[~~currentTime]){
          this.quizs = this.quizsByTimes[~~currentTime]
          setTimeout(() => {
            this.videoElement.pause()
          }, 1000);
          this.isBeginQuiz = true
        }
      },
      videoEnd() {
        if(this.quizsByTimes[-1]){
          this.quizs = this.quizsByTimes[-1]
          
          this.isBeginQuiz = true
          this.videoDone = true
        }
      },
      addTextarea(){
        this.update_block_content.push('')
        this.update_check_content.push('待验证')
      },
      removeInput(index){
        console.log(index)
        this.update_block_content.splice(index, 1)
        this.update_check_content.splice(index,1)
      },
      getFile(e){
        const file = e.target.files[0];
        
        console.log(file)
        if(file.size / 1024 /1024 > 10){
          this.$message({
                    message: '大小不能超过5mb限制，请重新上传~',
                    type: 'error'
                  });
          this.file  = null
          return false
        }else{
          this.file = file

        }
        
      },
      getVideoFile(e){
        const file = e.target.files[0];
        
        console.log(file)
        if(file.size / 1024 /1024 > 1024){
          this.$message({
                    message: '大小不能超过1GB限制，请重新上传~',
                    type: 'error'
                  });
          this.file  = null
          return false
        }else{
          this.file = file

        }
      },
      uploadFile(){
       
        let param = new FormData(); //创建form对象
        let _this = this
        this.proVisible = true
        this.process = '0'
        param.append('file',this.file);//通过append向form对象添加数据


        param.append('function', 'uploadBlockAttach')
        param.append('project_id', this.project_id )
        param.append('attach_type', this.selectType)
        param.append('attach_intro', this.intro)
        param.append('team_code', this.application_team_code)
        param.append('account_id', (this.lookType == 'student' ?this.now_block_account_id:this.accountid))
        param.append('block_id', this.content.id)
        param.append('attach_name', this.videoName)
        param.append('ecology', this.ecology)

        console.log(param.get('file')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
        let config = {
          headers:{'Content-Type':'multipart/form-data'},
          onUploadProgress:function(progressEvent){ //原生获取上传进度的事件
            console.log(progressEvent)
            if(progressEvent.lengthComputable){
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
              let loaded = progressEvent.loaded
              let total = progressEvent.total
              _this.$nextTick(() => {
                _this.process = parseFloat(((loaded/total) * 100).toFixed(1))
              })
              console.log(_this.process)
            }
          }
        }; //添加请求头
        upload({param,config})
        .then(response=>{
          let data = response.data
          if (data == false){
              this.$message({
                message: '提交失败~',
                type: 'erroe'
              });
          }else{
              this.$message({
                message: '上传成功~',
                type: 'success'
              });
          }
          this.upLoading = false
          this.proVisible = false
          this.getBlockAttaches()
        })
      
      },

      deleteAttach(index){
        let param = {
          attach_id:this.content_attach[index]._id.$id,
          project_id:this.project_id,
          account_id:this.accountid,
          team_code:this.application_team_code
        }
        if(this.lookType == 'student'){
            param.student_id = this.studentid
          }

          param.ecology = this.ecology

         netService({
          functionName: 'removeBlockAttach',
          ...param
        
          }).then(data=>{

            // console.log(data)
            this.getBlockAttaches()
            
          })
        return false;
      },
      editAttach(index){
        this.content_attach[index]['edit'] = true
        this.content_attach = [...this.content_attach]
        
      },
      confirmAttach(index){
        this.content_attach[index]['edit'] = false

        
        netService({
          functionName: 'updateAttachName',
         
          attach_id: this.content_attach[index]._id.$id,
          new_name: this.content_attach[index].attach_name
          }).then(data=>{
            // console.log(data)
            this.getBlockAttaches()
            
            
          })
      },
      delComment(id){
        console.log(id)
        netService({
          functionName: 'deleteBlockComment',
          
          student_block_id: this.student_block_id,
          comment_id: id,
        
          }).then(data=>{
            // console.log(data)
            this.getSB()
            
          })
      },

      editComment(item){
        item['edit'] = true
        this.comments = {
          ...this.comments
        }
        console.log(this.comments)
      },

      editCommentComfirm(item){
        netService({
          functionName: 'editBlockComment',
         
          comment_id: item._id.$id,
          comment_text :item.comment_text
        
          }).then(data=>{
            // console.log(data)
            this.getSB()
            
          })
      },
      getPdf(){
        if(!this.content || !this.content.id || this.content.id == ''){
          this.$message.error('请选择左边模块～')
          return 
        }
        console.log('121343')
        console.log(this.project_id)
        console.log(this.lookType == 'student' ?this.studentAccountId:this.accountid)
        console.log(this.application_team_code)
        this.$router.push({
          path: 'toPrint', query: { 
            project_id: this.project_id,
            // account_id :(this.lookType == 'student' ?this.studentAccountId:this.accountid),
            account_id:this.accountid,
            team_code: this.application_team_code,
            project_product:this.project_product,
            block_id: this.content.id
        }})
        
      },

      closeDialog() {
        this.videoDialogVisible = false
        this.videoAddress = ''
      },

      changePage(e){
        console.log(e)
        if(e != 1){
          const index = e - 2
          this.update_block_content = this.past_content_array[index]['block_content']
          if(this.past_content_array[index]['check_content']){
              this.update_check_content = this.past_content_array[index]['check_content']
          }else{
                this.update_check_content = new Array(this.update_block_content.length).fill('待验证');
          }
          const date = new Date(this.past_content_array[index]['save_time']*1000)
          this.latest_save_time = this.past_content_array[index]['save_time'] ? date.toLocaleDateString() + ' '+date.toLocaleTimeString() : '没有记录~'
        }else{
          this.update_block_content = this.block_content
          if(this.check_content){
                this.update_check_content = this.check_content
          }else{
                this.update_check_content = new Array(this.update_block_content.length).fill('待验证');
          }
          const date = new Date(this.now_save_time *1000)
          this.latest_save_time = this.now_save_time ? date.toLocaleDateString() + ' '+date.toLocaleTimeString() : '没有记录~'
        }
        
      },
      sendEmail(){
          sendEmail(this.studentid,this.project_id).then(res=>{
              this.$message({
                  message:'提交成功',
                  type:'success'
              })
          })
      },
      updateCheck(){
          let param = {
            block_id:this.content.id,
            project_id:this.project_id,
            team_code:this.application_team_code,
            account_id:this.accountid,
            check_content:this.update_check_content
          }
          if(this.lookType == 'student'){
            param.student_id = this.studentid
          }
          updateStudentBlockCheck(param).then(res=>{
              this.$message.success('更新成功')
          })
      }
      
    },
    
  data() {
    return {
      s_a: false,
      isAnswer: false,
      educator_comment: '',
      lookType: 'student',
      latest_save_time: '',
      template_description:'',
      template_name:'',
      videoDone: false,
      process: '0',
      proVisible: false,
     
      update_block_content:[''],
      update_check_content:'',
      my_block:'',
      comments: [],

      videoElement: null,
      session_id: '',
      menuItems:[],
      defaultProps: {
        children: 'children',
        label: 'label',
        content_info: 'content_info'
      },
      checklist: [],
      content: {},
      studentId: '',
      quizs: [],
      isBeginQuiz: false,
      quizsByTimes: {},
      content_quiz: [],

      content_attach: [],
      activeNames: ['1','appendix'],
      file: null,
      studentid: '',
      nowAttachName: '',
      past_content_array: [],
      block_content: [],
      check_content:'',
      options: [{
        value: 'video',
        label: 'video'
      }, {
        value: 'pdf',
        label: 'pdf'
      }, {
        value: 'image',
        label: 'image'
      }, {
        value: 'file',
        label: '网盘文件'
      }, {
        value: 'gongzhonghao',
        label: '公众号文章'
      }, {
        value: 'website',
        label: '网站'
      }],
      selectType: 'video',
      videoPath: '',
      videoName: '',
      intro: '',
      centerDialogVisible: false,
      upLoading: false,

      selectOptions: [],
      selectValue: '',
      attach_arr: [],
      appendix: false,
      dialogVisible: false,
      videoDialogVisible: false, 
      videoAddress: '',
      address: '',
      now_block_account_id: '',
      accountid:'',
      forbid_edit:false,
      draft_status:0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.attach-img{
  /*width:100%;*/
  /*margin-bottom:50px;*/
  /*width: 200px;*/
  height: 150px;
  display: block;
  margin:0 auto 10px auto;
  border:1px solid #ddd;
}
.check-box{
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #409EFF;
  padding: 10px;
}
.check-box-item{
  /* word-wrap:break-word; 
word-break:break-all;  */
word-break:normal;
white-space: pre-wrap;
margin-bottom: 10px;
}
.video_container {
  height: 800px;
}
.video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
}
.appendix {
  margin: 20px;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: 300px 300px 300px; */
}
.attach_arr {
  margin: 20px 0;
}
.attach_arr p {
  font-size: 13px;
}
.select {
  margin: 20px 0;
}
.intro {
  margin: 20px 0;
}
.pdf{
  position: absolute; 
  right: 10px;
  width:100px;
  height:40px;
  top: -10px;
  font-size:15px;
  /* font-weight:200; */
}
.draft{
  position: absolute; 
  right: 140px;
  width:100px;
  height:40px;
  top: -10px;
  font-size:15px;
  font-weight: normal;
  /* font-weight:300; */
}
.file{
  margin-top: 20px;
}
.templateHead{
  position: relative;
  padding: 0 10px;
}
.comments{
  margin-top: 30px;
}
  .Home {
    width: 100%;
  }
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  
  .el-aside {
    color: #333;
  }
  .console{
    position: absolute;
    bottom: 0;
    height: 300px;
    width: calc(100% - 201px);
    border-top: 1px solid #ccc;
  }
  .el-tree-node{
    padding: 15px 0;
  }
  .title{
    font-size: 20px;
  }
  #videoPlay{
    width: 100%;
  }
  .title-description{
    font-size: 14px;
    display: contents;
  }
  .content_instruction{
    display: block;
    font-size: 12px;
    margin-top:10px;
    white-space: pre-line;
    word-wrap: break-word;
  }
  .deleteAttach{
    cursor: pointer;
    margin-left: 10px
  }
.progress{
  width: 300px;
}
.attachType {
  display: inline-block;
  margin-right: 10px;
}
</style>
<style>
.addCommets .el-textarea__inner{
  height: 100px;
}

.editComments .el-textarea__inner{
  height: 100px;
}
.el-textarea__inner{
height: 350px;
}
.intro .el-textarea__inner{
    height: 150px;
    width: 400px;
}
.btn {
  margin: 20px 0;
}
.green{
  color: green
}
.el-tree-node{
  white-space: normal!important;
  background: rgb(238, 241, 246);
}
.el-tree-node__content{
    padding: 15px 0;
    
  }
  .el-tree-node__label{
    /* overflow: hidden;
    text-overflow: ellipsis; */
  }
  .el-tree-node__expand-icon + .el-tree-node__label{
    font-weight:bolder;
  }
  .el-tree-node__expand-icon.is-leaf + .el-tree-node__label{
    font-weight: normal;
  }
  video::-internal-media-controls-download-button { 
    display:none; 
  }

  video::-webkit-media-controls-enclosure { 
    overflow:hidden; 
  }
  video::-webkit-media-controls-panel { 
    width: calc(100% + 30px); 
  }
.el-checkbox__input{
  vertical-align: top;
}
.tip{
  margin-left: 10px;
  font-size: 40%;
  background: #dddddd;
  border-radius: 5px;
  padding: 6px;
}

</style>

