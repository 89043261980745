<template>
  <div class="ClassList">
      <Head />
      <el-table
      :data="tableData2"
      style="width: 100%"
      :row-class-name="tableRowClassName"
       @row-click="rowClik"
      >
     
      <el-table-column
        prop="name"
        label="项目名称"
        >
      </el-table-column>
      <el-table-column
        prop="session_year"
        label="项目时间">
      </el-table-column>
      
    </el-table>
  </div>
</template>

<script>
import { getStudentProjects } from '../service.js'
import Head from './Head'
import Cookie from 'js-cookie'

export default {
  name: 'ClassList',
  components: {
    Head,
  },
  mounted() {
    const studentid = Cookie.get('studentid')

    const ecology = Cookie.get('ecology')

    getStudentProjects({
        student_id:studentid,
        ecology:ecology
    }).then((data) => {
      console.log(data)
        data.map(i => {
        this.tableData2.push({
          application_team_code:i.application_team_code,
          name: i.project_name,
          sessionNum: i.project_price,
          template_id: i.template_id,
          session_year: i.project_year,
          id: i._id.$id,
          project_template_id: i.template_id,
          project_code:i.project_code
        })
      })
    })
    // const studentid = Cookie.get('studentid')
    // this.class_id = this.$router.currentRoute.query.class_id
    // getSessions({
    //   class_id: this.class_id
    // }).then((data) => {
    //   console.log(data)
    //   data.map(i => {
    //     this.tableData2.push({
    //       name: i.session_info.session_name,
    //       sessionNum: i.session_first_content_count,
    //       seession_id: i._id.$id,
    //       session_description: i.session_info.session_description,
    //     })
    //   })
    // })
  },
  methods: {
      tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      rowClik(row) {
        // console.log(111,row)
        const studentid = Cookie.get('studentid')
        this.$router.push({ path: 'TeamCodeList', query: { project_id: row.id, from: 'student' , account_id: Cookie.get('accountid'), educator_project_id: row.id, project_template_id: row.project_template_id , project_product: row.project_code}})
      },
      getPro(){}
    },
    data() {
      return {
        tableData2: [],
        class_id: '',
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
  }
</style>
