<template>
  <el-header style="text-align: right; font-size: 12px">
    <el-dropdown>
      <i class="el-icon-setting" style="margin-right: 15px"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item ><div @click="logout()">退出</div></el-dropdown-item>
       
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <span>{{name}}</span> -->
  </el-header>
</template>

<script>
import Cookie from 'js-cookie'

export default {
  name: 'Head',
  props: {
    
  },
  computed:{
    // name(){
    //   const name = Cookie.get('user_name')
    //   const reg = /[A-Z]+/g
    //   const res = name.replace(reg, ($2) => { return ' ' + $2 })
    //   return res.split(' ').reverse().join(' ')
    // }
  },
  methods: {
   logout(){
     console.log('out')
     Cookie.remove('studentid');
     Cookie.remove('ecology')

     this.$router.push('/login')
   } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
